import { NavigationContent } from './../common/model/navigation-content';
import { Injectable, Injector } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CollectionService extends ApiBaseService {

    constructor(injector: Injector) {
        super(injector);
    }

    // Needs to be implemented on custom Strapi Controller (See page controller)
    public getCollectionItemBySlug = (collectionName: string, slug: string) => {
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', 'deep');
        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/' + collectionName + '/find-by-slug/' + slug, httpOptions);
        return result;
    }

    public getCollection = (collectionName: string) => {
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', 'deep');
        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/' + collectionName, httpOptions);
        return result;
    }

    public getCollectionById = (collectionName: string, id: string) => {
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', 'deep');
        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/' + collectionName + '/' + id, httpOptions);
        return result;
    }
}
