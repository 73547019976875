import { LocalizationService } from './../../../business/common/localization.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/business/api/navigation.service';
import { NavigationContent } from 'src/app/business/common/model/navigation-content';

@Component({
    selector: 'riv-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    navigationData: NavigationContent[];
    pageData: any;
    selectedLanguage = "";

    constructor(
        private navigationService: NavigationService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.selectedLanguage = this.localizationService.currentLanguage.displayName;
        this.navigationService.getNavigation('footer-full-menu-navigation').subscribe(result => {
            this.navigationData = result;
        });
        this.navigationService.getFooterData().subscribe(result => {
            this.pageData = result;
        });
    }

    toggleLanguage(language) {
        this.localizationService.changeLanguageByName(language);
    }

}
