import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CoreActions } from 'src/app/core.actions';
import { NavigationContent } from './../common/model/navigation-content';
import { ApiBaseService } from './api-base.service';
import { ICoreState } from '../common/model/ICoreState';

@Injectable({
    providedIn: 'root'
})
export class NavigationService extends ApiBaseService {
    requestCount$: Observable<number>;

    constructor(
        injector: Injector,
        private router: Router,
        private coreActions: CoreActions,
        private store: Store<{ core: ICoreState }>,
    ) {
        
        super(injector);
        this.requestCount$ = store.select('core', 'loadingQueriesCount');
    }

    public getNavigation = (navigationSlug: string) => {
        this.coreActions.addLoadingQuery();
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('type', 'TREE');

        var result: Observable<NavigationContent[]> = this.http.get<any>(
            this.baseUrl + '/navigation/render/' + navigationSlug, httpOptions).pipe(
                tap(x => {
                    this.coreActions.removeLoadingQuery();
                }),
                map(this.mapNavigationResponse.bind(this)),
            );
        return result;
    }

    private mapNavigationResponse(navigation: any[]): NavigationContent[] {
        let result: NavigationContent[] = new Array();
        if (navigation) {
            navigation.forEach(element => {
                let mappedItems = this.mapNavigationResponse(element.items);
                let sanitizedPath = element.path.replace(/\/undefined/g, "");
                let sanitizedSlug = "";
                if (!element.external) {
                    sanitizedPath = "/" + this.localizationService.currentLanguage.code + sanitizedPath;
                    sanitizedSlug = element.slug.replace(/undefined-/g, "").replace("undefined", "");
                }
                    result.push({
                    external: element.external,
                    id: element.id,
                    items: mappedItems,
                    order: element.order,
                    path: sanitizedPath,
                    relatedPageData: element.relatedPageData,
                    slug: sanitizedSlug,
                    title: element.title,
                    type: element.type,
                    uiRouterKey: element.uiRouterKey,
                    audience: element.audience,
                } as NavigationContent);
            });
        }
        return result;
    }

    public getFooterData = () => {
        this.coreActions.addLoadingQuery();
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', '*');

        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/footer', httpOptions).pipe(
                tap(x => {
                    this.coreActions.removeLoadingQuery();
                })
            );
        return result;
    }

    public getHomeData = () => {
        this.coreActions.addLoadingQuery();
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', 'home.image, home.card1_icon, home.card2_icon');
        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/home', httpOptions).pipe(
                tap(x => {
                    this.coreActions.removeLoadingQuery();
                })
            );
        return result;
    }

    public getSurveyData = () => {
        this.coreActions.addLoadingQuery();
        var httpOptions = this.httpOptions;
        httpOptions.params = httpOptions.params.set('populate', 'survey.MainImage');
        var result: Observable<any> = this.http.get<any>(
            this.baseUrl + '/survey', httpOptions).pipe(
                tap(x => {
                    this.coreActions.removeLoadingQuery();
                })
            );
        return result;
    }

    public navigateToBlog() {
        this.router.navigateByUrl(this.localizationService.currentLanguage.code + '/help/news');
    }

    public navigateToPost(postId: string){
        this.router.navigateByUrl(this.router.url + '/' + postId);
    }

    navigateByUrl( url: string) {
        const localization = this.localizationService.currentLanguage.code;
        this.router.navigateByUrl( localization + url);
    }
}
