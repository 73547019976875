import { createReducer, on } from "@ngrx/store";

import { ICoreState } from "../business/common/model/ICoreState";
import { ADD_LOADING_QUERY, REMOVE_LOADING_QUERY } from "../core.actions";

const initialState: ICoreState = {
    loadingSpinnerType: '',
    loadingQueriesCount: 0,
}


function addLoadingQuery(state: ICoreState, action): ICoreState {
    return {
        ...state,
        loadingQueriesCount: state.loadingQueriesCount + 1,
        loadingSpinnerType: action.loadinType,
    };
}

function removeLoadingQuery(state: ICoreState): ICoreState {
    return {
        ...state,
        loadingQueriesCount: state.loadingQueriesCount == 0 ? 0 : state.loadingQueriesCount - 1,
    };
}

export const coreReducer = createReducer(
    initialState,
    on(ADD_LOADING_QUERY, (state, action) => addLoadingQuery(state, action)),
    on(REMOVE_LOADING_QUERY, (state) => removeLoadingQuery(state)),
);