<div class="breadcrumb">
    <div class="breadcrumb__item">
    <img src="../../../../assets/img/icons/home__icon.svg" height="24" width="24" alt="{{homeTitle}}">
        <a href="/">{{homeTitle}}</a>
        <span class="item__arrow"></span>
    </div>
    <div class="breadcrumb__item" *ngFor="let node of breadcrumbNodes; let lastNode = last">
         <a [href]="node.slug" [ngClass]="{'item__primary': lastNode}">{{node.title}}</a>
         <span *ngIf="!lastNode" class="item__arrow"></span>
    </div>
<div>

