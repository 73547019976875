import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'riv-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {


    @Input() breadcrumbNodes;
    @Input() homeTitle;

    constructor(
    ) { }

}
