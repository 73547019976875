import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CollectionService } from 'src/app/business/api/collection.service';
import { NavigationService } from 'src/app/business/api/navigation.service';
import { CoreActions } from 'src/app/core.actions';

@Injectable({
    providedIn: 'root',
})
export class PageResolver implements Resolve<any> {
    constructor(
        private collectionService: CollectionService,
        private navigationService: NavigationService,
        private coreActions: CoreActions,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const pageSlug = route.params['page'];

        const getPageData$ = this.collectionService.getCollectionItemBySlug('page', pageSlug).pipe(
            catchError(() => {
                this.coreActions.removeLoadingQuery();
                this.navigationService.navigateByUrl('/not-found');
                return of(null);
            }),
        );
        const getBreadcrumbData$ = this.navigationService.getNavigation('breadcrumb-navigation');

        return forkJoin({ pageData: getPageData$, breadcrumbData: getBreadcrumbData$ });
    }
}
