import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface Language {
    code: string;
    displayName: string;
}

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    constructor(private router: Router) {}

    public get currentLanguage(): Language {
        return this.getLanguageByCode(localStorage.getItem('locale'));
    }

    public languages: Language[] = [
        { code: 'en', displayName: 'English' },
        { code: 'fr', displayName: 'Français' },
    ];

    private getLanguageByCode(code): Language {
        var lang: Language;
        this.languages.forEach((language) => {
            if (language.code == code) {
                lang = language;
            }
        });
        return lang;
    }

    private getLanguageByName(name): Language {
        var lang: Language;
        this.languages.forEach((language) => {
            if (language.displayName == name) {
                lang = language;
            }
        });
        return lang;
    }

    public changeLanguageByName(langName: string) {
        var newLanguage = this.getLanguageByName(langName);
        this.changeUrlLanguage(newLanguage);
    }

    public changeLanguageByCode(langCode: string) {
        var newLanguage = this.getLanguageByCode(langCode);
        this.changeUrlLanguage(newLanguage);
    }

    private changeUrlLanguage(newLanguage) {
        var newUrl = this.router.url.replace(
            '/' + this.currentLanguage.code,
            '/' + newLanguage.code,
        );

        localStorage.clear();
        localStorage.setItem('locale', newLanguage.code);
        window.location.href = newUrl;
    }
}
