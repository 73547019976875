
<div class="full-menu container-grid">
    <div class="full-menu__close-button">
        <button class="close" (click)="closeFullMenu()">
            <span class="cross"></span>
            <span class="sr-only">Close</span>
        </button>
    </div>
    <div *ngFor="let column of navigationData"
            [ngClass]="{'grid-column-2':  column.audience && column.audience.includes('column-size-2'),
            'grid-column-3': column.audience && column.audience.includes('column-size-3') }"
            class="full-menu__menu__column">
        <ul *ngFor="let columnSection of column.items"
            class="full-menu__menu">
            <li *ngFor="let item of columnSection.items"
                [ngClass]="{'menu__item': !item.audience || item.audience.includes('language-button') || item.audience.includes('secondary-alt-button')}">

                <a *ngIf="!item.external && (!item.audience || !item.audience.includes( 'language-button') && !item.audience.includes('column-header'))"
                    (click)="closeFullMenu()" [routerLink]="item.path"
                    [ngClass]="{'menu__title':  item.audience && item.audience.includes('column-header-link'),
                                'secondary-alt': item.audience && item.audience.includes('secondary-alt-button') }">
                    <span>{{item.title}}</span>
                </a>

                <a *ngIf="item.external && (!item.audience || !item.audience.includes( 'language-button') && !item.audience.includes('column-header'))"
                    [href]="item.path" 
                    [attr.target]="item.audience?.includes('external-same-tab')? null : '_blank'" 
                    [ngClass]="{'menu__title':  item.audience && item.audience.includes('column-header-link'),
                                'secondary-alt': item.audience && item.audience.includes('secondary-alt-button') }">
                    <span>
                        {{item.title}}<i *ngIf="!item.audience?.includes('external-same-tab')" class="icon">&nbsp;<img class="external-link" src="../../../../../assets/img/icons/external-link-black__icon.svg" width="12" height="12" alt="External link icon"></i>
                    </span>
                </a>
                <span *ngIf="item.audience && item.audience.includes('column-header')" class="menu__title"> {{item.title}}</span>

                <button *ngIf="item.audience && item.audience.includes('language-button')"
                        class="language"
                        [disabled]="selectedLanguage == item.title"
                        (click)="toggleLanguage(item.title)">{{item.title}}
                    <span *ngIf="selectedLanguage == item.title"
                            class="circle"></span>
                </button>
            </li>
        </ul>
    </div>
</div>
