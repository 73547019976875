import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './presentation/layout/page-layout/page-layout.component';
import { PageResolver } from './presentation/pages/cms-page-renderer/cms-page-renderer.resolver';

const routes: Routes = [
    {
        path: '',
        redirectTo: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en',
        pathMatch: 'full',
    },
    {
        path: ':locale',
        component: PageLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./presentation/pages/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: ':page',
                loadChildren: () =>
                    import('./presentation/pages/cms-page-renderer/cms-page-renderer.module').then(
                        (m) => m.CMSPageRendererModule,
                    ),
                data: { reuse: true },
                resolve: {
                    resolvedData: PageResolver,
                },
            },
            {
                path: ':parentPage/:page',
                loadChildren: () =>
                    import('./presentation/pages/cms-page-renderer/cms-page-renderer.module').then(
                        (m) => m.CMSPageRendererModule,
                    ),
                data: { reuse: true },
                resolve: {
                    resolvedData: PageResolver,
                },
            },
            {
                path: ':parentPage/news/:id',
                loadChildren: () =>
                    import('./presentation/pages/post/post.module').then((m) => m.PostModule),
            },
            {
                path: '**',
                loadChildren: () =>
                    import('./presentation/pages/not-found/not-found.module').then(
                        (m) => m.NotFoundModule,
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
