import { Store, createAction, props } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { ICoreState } from './business/common/model/ICoreState';

export const ADD_LOADING_QUERY = createAction('[Core] Add Loading Query', props<{ loadingType: string }>());
export const REMOVE_LOADING_QUERY = createAction('[Core] Remove Loading Query');

@Injectable({
    providedIn: 'root'
})
export class CoreActions {
    constructor(private store: Store<{ core: ICoreState }>) { }
    
    public addLoadingQuery(loadingType = "") {
        this.store.dispatch(ADD_LOADING_QUERY({ loadingType: loadingType }));
    }
    
    public removeLoadingQuery() {
        this.store.dispatch(REMOVE_LOADING_QUERY());
    }
}