import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'sbweb-riv';
    gtmContainerId = environment.gtm.containerId; 


    constructor(private router: Router, private renderer: Renderer2) {}

    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.router.url.includes('contact-us')) {
            this.renderer.addClass(document.body, 'show-recaptcha');
          } else {
            this.renderer.removeClass(document.body, 'show-recaptcha');
          }
        }
      });
    }
}
