import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ButtonModule } from '../../ui/common/button/button.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        RouterModule,
    ],
    exports: [
        FooterComponent,
    ]
})
export class FooterModule { }
