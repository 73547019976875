import { NavigationService } from './../../../business/api/navigation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ICoreState } from 'src/app/business/common/model/ICoreState';

@Component({
    selector: 'riv-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent implements OnInit {
    loadingSpinnerType$: Observable<string>;
    loadingQueries$: Observable<number>;

    constructor(private route: ActivatedRoute,
                private navigationService: NavigationService,
                private store: Store<{ core: ICoreState }>,
    ) {
        this.loadingSpinnerType$ = store.select('core', 'loadingSpinnerType');
        this.loadingQueries$ = store.select('core', 'loadingQueriesCount');
    }

    ngOnInit(): void {
        var locale = this.route.snapshot.params['locale'];
        if (locale != 'en' && locale != 'fr') {
            localStorage.setItem('locale', "en");
            this.navigationService.navigateByUrl('/not-found');
        } else {
            localStorage.setItem('locale', locale);
        }

        this.loadingQueries$.subscribe((value) => {
            const shouldDisableScroll = value > 0;
            this.togglePageScroll(shouldDisableScroll);
        });
        
    }

    togglePageScroll(disableScroll: boolean) {
        const htmlSelector = this.getHtmlSelector();
        htmlSelector.classList.toggle('no-scroll', disableScroll);
    }
    

    private getHtmlSelector() {
        return document.querySelector('html') as HTMLElement;
    }
}
