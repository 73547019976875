import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ButtonModule } from '../../ui/common/button/button.module';
import { MenuModule } from '../../ui/header/menu/menu.module';
import { MobileMenuModule } from '../../ui/header/mobile-menu/mobile-menu.module';
import { LanguagueBarModule } from '../../ui/header/language-bar/languague-bar.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MenuModule,
        MobileMenuModule,
        LanguagueBarModule,
        RouterModule,
    ],
    exports: [
        HeaderComponent,
    ]
})
export class HeaderModule { }
