import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationService } from 'src/app/business/api/navigation.service';
import { LocalizationService } from 'src/app/business/common/localization.service';
import { NavigationContent } from 'src/app/business/common/model/navigation-content';

@Component({
  selector: 'riv-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

    @Output() onClosed = new EventEmitter<boolean>();

    closeFullMenu() {
        const htmlSelector = this.getHtmlSelector();

        this.onClosed.emit();
        htmlSelector.classList.remove('no-scroll')
    }

    navigationData: NavigationContent[];
    selectedLanguage = "";

    constructor(
        private navigationService: NavigationService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.selectedLanguage = this.localizationService.currentLanguage.displayName;
        this.navigationService.getNavigation('hamburger-mobile-menu').subscribe(result => {
            this.navigationData = result;
        });
    }

    private getHtmlSelector() {
        return document.querySelector('html') as HTMLElement;
    }

    toggleLanguage(language) {
        this.localizationService.changeLanguageByName(language);
    }
}
