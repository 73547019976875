import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

export interface GtmEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private gtagLoaded = false;

  constructor() {
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      window['dataLayer'] = [];
    }
  }

  private ensureGtagReady(callback: () => void) {
    if (this.gtagLoaded) {
      callback();
    } else {
      const interval = setInterval(() => {
        if (typeof gtag === 'function') {
          this.gtagLoaded = true;
          clearInterval(interval);
          callback();
        }
      }, 100);
    }
  }

  trigger(event: GtmEvent) {
    window['dataLayer'].push({
      'event': 'gtm.customEvent',
      'gtm.category': event.category,
      'gtm.action': event.action,
      'gtm.label': event.label,
      'gtm.value': event.value
    });
  }

  addEvent(content_id: string) {
    this.ensureGtagReady(() => {
      gtag("event", "select_content", {
        content_type: "expander_open",
        content_id: content_id
      });
    });
  }

  loadGtm() {
    // Load the gtag script
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtm.id}`;
    gtagScript.async = true;
    gtagScript.defer = true;
    document.head.appendChild(gtagScript);

    gtagScript.onload = () => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['gtag'] = function () { window['dataLayer'].push(arguments); };
      gtag('js', new Date());
      gtag('config', environment.gtm.id);
      this.gtagLoaded = true;
    };

    // Load the GTM script
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });

      const f = d.getElementsByTagName(s)[0];
      const j: HTMLScriptElement = document.createElement(s) as HTMLScriptElement;
      const dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', environment.gtm.containerId);
  }
}
