import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { ButtonModule } from '../../common/button/button.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        MenuComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        RouterModule,
    ],
    exports: [
        MenuComponent,
    ]
})
export class MenuModule { }
