import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationService } from './navigation.service';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
    providers: [
        NavigationService
    ],
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
    ]
})
export class NavigationServiceModule { }
