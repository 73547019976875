import { LocalizationService } from './../../../../business/common/localization.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'riv-language-bar',
    templateUrl: './language-bar.component.html',
    styleUrls: ['./language-bar.component.scss']
})
export class LanguageBarComponent implements OnInit {

    languageMenuOpen = false;
    selectedLanguage = this.localizationService.currentLanguage.displayName;
    nonSelectedLanguage = this.localizationService.languages.filter(
        lang => lang.displayName != this.selectedLanguage
    )[0].displayName;

    constructor(private localizationService: LocalizationService) { }

    ngOnInit(): void {
    }

    toggleLanguageMenu() {
        this.languageMenuOpen = !this.languageMenuOpen;
    }

    toggleLanguage() {
        this.localizationService.changeLanguageByName(this.nonSelectedLanguage);
    }

}
