<div class="language">
    <div class="container-grid">
        <div class="language__option">
            <div class="language__container">
                <div class="container">
                    <div class="circle lvl-3"></div>
                    <div class="circle lvl-2"></div>
                    <div class="circle lvl-1">
                    </div>
                </div>
                <div class="language__value"
                     (click)="toggleLanguageMenu()">
                    <span class="value__label">{{selectedLanguage}}</span>
                    <div class="value__chevron"
                         [ngClass]="{'open':  languageMenuOpen }"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="language__menu-option container-grid">
    <div class="language__menu"
         [ngClass]="{'open':  languageMenuOpen }">
        <span (click)="toggleLanguage()">{{nonSelectedLanguage}}</span>
    </div>
</div>
