import { LocalizationService } from './../common/localization.service';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpOptions } from './model/http-options';

@Injectable({
    providedIn: 'root'
})
export abstract class ApiBaseService {

    get httpOptions(): HttpOptions {
        var params = new HttpParams();
        params = params.set("locale", this.locale)
        return {
            headers: new HttpHeaders({
                Authorization: 'bearer ' + environment.strapiToken,
            }),
            params: params,
        }
    };

    protected get locale() {
        return localStorage.getItem('locale');
    }


    protected http: HttpClient;
    protected localizationService: LocalizationService;
    protected baseUrl = environment.strapiUrl + '/' + environment.apiPath;

    constructor(
        injector: Injector
    ) {
        this.http = injector.get(HttpClient);
        this.localizationService = injector.get(LocalizationService);
    }
}
