import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMenuComponent } from './mobile-menu.component';
import { ButtonModule } from '../../common/button/button.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        MobileMenuComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        RouterModule
    ],
    exports: [
        MobileMenuComponent ,
    ]
})
export class MobileMenuModule { }
