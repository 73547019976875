import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout.component';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { SpinnerModule } from '../../ui/common/spinner/spinner.module';


@NgModule({
    declarations: [
        PageLayoutComponent
    ],
    imports: [
        CommonModule,
        HeaderModule,
        FooterModule,
        AppRoutingModule,
        BreadcrumbModule,
        SpinnerModule,
    ],
    exports: [
        PageLayoutComponent,
    ]
})
export class PageLayoutModule { }
